import _ from 'lodash'
import { rest } from '../utils/rest'
import {
    GET_ERRORS,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    VERIFY_FORGOT_PASSWORD,
    RESEND_VERIFICATION
} from './types';


// Login - Get User Token
export const resendVerification = id => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .get(`auth/resend/${id}`)
            .then(res => {
                // Set current user
                dispatch({
                    type:RESEND_VERIFICATION
                })

                resolve(res.data)
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
                reject(err)
            })
            .then(function () {
            })
    });
};


// Login - Get User Token
export const forgotPassword = userData => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .post('auth/forgot.request', userData)
            .then(res => {
                // Set current user
                dispatch({
                    type:FORGOT_PASSWORD
                })

                resolve(res.data)
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
                reject(err)
            })
            .then(function () {
            })
    });

};


// Login - Get User Token
export const verifyForgotPassword = code => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .get('auth/forgot.verify', {
                params:{
                    code:code
                }
            })
            .then(res => {
                // Set current user
                dispatch({
                    type:VERIFY_FORGOT_PASSWORD
                })

                resolve(res.data)
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
                reject(err)
            })
            .then(function () {
            })
    });

};


// Login - Get User Token
export const resetPassword = args => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .post('auth/reset', args)
            .then(res => {
                // Set current user
                dispatch({
                    type:RESET_PASSWORD
                })

                resolve(res.data)
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
                reject(err)
            })
            .then(function () {
            })
    });

};

