import { Link as Glink } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import classnames from "classnames"
import { displayAvatar } from "../utils/helpers"
import UserContext from "../context/CurrentUserContext"
import Logo from "./Common/Logo"
import { isPremiumProcess } from "../utils/helpers"
import { isNil, has } from "lodash"
import 'react-notifications/lib/notifications.css'
import "../sass/main.scss"
import { connect } from "react-redux"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdown:false,
      windowWidth: null
    }

    this.mediaQuery = {
      desktop: 1200,
      tablet: 768,
      phone: 576,
    };
  }

  componentDidMount() {
    this.setState({windowWidth: document.body.clientWidth})
  }

  onToggleShow(e) {
    e.preventDefault()
    this.setState({dropdown:!this.state.dropdown})
  }
  
  render() {
    const { siteTitle, auth, profile } = this.props
    return (
      <UserContext.Consumer>
        {cuser => (<header className="bg-dark" 
          style={{
            marginBottom: `1.45rem`,
          }}
        >
          <div
            id="header"
            className="container"
            style={{
              padding: this.state.windowWidth <  this.mediaQuery.phone ? `1.05rem 15px` : `1.45rem 15px`,
              height:this.state.windowWidth <  this.mediaQuery.phone ? 180 : `auto`
            }}
          >
            <div className="row">
              <div className="left col-lg-4 col-12">
                <h1 style={{ margin: 0 }}>
                  <Glink to="/">
                    <Logo alt={siteTitle} />
                  </Glink>
                </h1>
              </div>
              <div className="right col-lg-8 col-12">
                <nav className="navbar navbar-expand-lg navbar-light text-white">
                <div className="navbar-collapse" id="navbarNav" style={{
                  fontFamily:"Roboto"
                }}>
                  <ul className="navbar-nav d-flex" style={{width:"100%", fontSize:18}}>
                    <li className="nav-item active mb-0">
                      <Glink className="nav-link  text-white" to={'/'}>Home <span className="sr-only">(current)</span></Glink>
                    </li>
                    <li className="nav-item mb-0">
                      <Glink className="nav-link  text-white" to={'/job'}>
                        {this.state.windowWidth > this.mediaQuery.phone ? 'Search Jobs' : 'Jobs'}
                      </Glink>
                    </li>
                    <li className="nav-item mb-0">
                      <Glink className="nav-link  text-white" to={'/company'}>
                        {this.state.windowWidth > this.mediaQuery.phone ? 'Search Company' : 'Company'}
                      </Glink>
                    </li>

                    <li className="nav-item mb-0">
                      <Glink className="nav-link  text-white" to={'/news'}>News</Glink>
                    </li>

                    <li className="nav-item mb-0 text-right col align-self-end">
                      {auth.isAuthenticated == false && 
                        <>
                          <Glink className="btn btn-outline-secondary text-white" to={'/login'}>Login</Glink>
                          <Glink className="btn btn-danger ml-2 text-white" to={'/register'}>Register</Glink>
                        </>
                      }
                      {auth.isAuthenticated && <div className={classnames("dropdown", {
                          'show':this.state.dropdown
                        })} style={{
                          display:"inline-block"
                        }}>
                          <a className="btn btn-secondary dropdown-toggle" href="#" onClick={this.onToggleShow.bind(this)} role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.dropdown ? 'true' : 'false'}>
                            {auth.user && has(auth.user, 'jobseekers_image') && auth.user.jobseekers_image && <img src={displayAvatar(auth.user.jobseekers_image)} className="rounded" width={20} />} {auth.user ? auth.user.jobseekers_firstname : auth.user.jobseekers_firstname}
                          </a>

                        <div className={classnames("dropdown-menu", {
                          'show':this.state.dropdown
                        })} aria-labelledby="dropdownMenuLink" style={{
                          position:"absolute",
                          willChange:"transform",
                          top:0,
                          width:200,
                          right:0,
                          transform:"translate3d(0px, 38px, 0px)",
                          WebkitTransform:"translate3d(0px, 38px, 0px)"
                        }}>
                           <>
                              <Glink className="dropdown-item" to="/user/dashboard">My Profile CV</Glink>
                              <Glink className="dropdown-item" to="/user/application">Application</Glink>
                              <div className="dropdown-divider"></div>
                              <Glink className="dropdown-item" to="/user/message">Message Box {(profile && profile.last_message && profile.last_message.length > 0 && profile.last_message[0].user_id == null) && <span className="badge badge-pill badge-danger">New</span>} </Glink>
                              <div className="dropdown-divider"></div>
                              {!isPremiumProcess(this.props.profile) && 
                                <>
                                  <Glink className="dropdown-item" to="/user/upgrade">Upgrade <span className="badge badge-pill badge-success">New</span></Glink>
                                  <div className="dropdown-divider"></div>
                                </>}

                              <Glink className="dropdown-item" to="/user/logout">Logout</Glink>
                            </>

                        </div>
                      </div>}
                    </li>
                    
                  </ul>
                </div>
                </nav>
              </div>
            </div>
            
          </div>
        </header>)}
      </UserContext.Consumer>
    )
  }
  
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const mapStateToProps = state => ({
  auth:state.auth,
  profile:state.user.profile
})

export default connect(mapStateToProps, {})(Header)
